import css from "./Engage.scss";

const Engage = ({ ...props }) => {
  const { mobile, email } = props.header;
  const isMobile = navigator.userAgent.match(
    /(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i
  );

  return (
    <div class={css.sticky}>
      <a
        class={css.encircle}
        href={"mailto:" + email + "?Subject=Let's meet!"}
        target="_blank"
      >
        📧
      </a>
      {isMobile != null && (
        <a
          class={css.encircle}
          href={"tel:" + mobile.replace(/-/g, "")}
          target="_blank"
        >
          🤙
        </a>
      )}
    </div>
  );
};

export default Engage;
